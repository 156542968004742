<template>
  <div id="cms-a-la-carte-show">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">À la Carte - Show</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Item name:"
                    label-for="item-name"
            >
              <b-form-input id="item-name" v-model="menuItem.item_name" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Category:"
                    label-for="remark-en"
            >
              <b-form-input id="remark-en" v-model="menuItem.category_name" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark in English:"
                    label-for="remark-en"
            >
              <b-form-input id="remark-en" v-model="menuItem.remark_en" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Remark in Dutch:"
                    label-for="remark-nl"
            >
              <b-form-input id="remark-nl" v-model="menuItem.remark_nl" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Price:"
                    label-for="price"
            >
              <b-form-input id="price" v-model="menuItem.price" disabled></b-form-input>
            </b-form-group>
            <router-link class="link-color" :to="{ name: 'CmsALaCarte'}" @click.prevent>
              <b-button type="reset" variant="success">Cancel</b-button>
            </router-link>
            <b-button type="submit" :to="{ name: 'CmsALaCarteUpdate', params: { id: `${this.$route.params.id}` }}" variant="warning" @submit.prevent>Edit</b-button>

          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue'

export default {
  data() {
    return {
      loading: true,
      menuItem: [],
    }
  },
  components: {
    SideMenu
  },
  beforeCreate () {
    this.axios
        .get(`https://batavia-backend.herokuapp.com/api/menu-item/${this.$route.params.id}`)
        .then(response => (
            this.menuItem = response.data
        ))
        .finally(() => {
          this.menuItem['price'] = this.menuItem['price'].toFixed(2)
          this.loading = false
        })
  }
}
</script>